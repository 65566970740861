import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './Onlinecourse.css';
import { useEffect } from 'react';
import image1 from './assets/retreatspagerishi.jpeg';
import image2 from './assets/rishi3.jpg';
import image3 from './assets/rishi6.jpg';
// import image2 from './assets/2.jpg';

function Retreats() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <div className="App">

      {/* Title Section */}
      <section className="title-sectiononline">
        <h1>Current Retreats</h1>
      </section>

      {/* Courses Section */}
      <section className="courses-sectiononline">
        <div className="courses-gridonline">
          {/* Course 1 */}
          <div className="course-cardonline">
            <div className="course-imageonline">
              <img loading='eager'  src={image1} alt="7 Days Private Retreat 'An Outward and Inward Journey' in Vietnam" preload="auto" />
            </div>
            <div className="course-detailsonline">
              <h2>6 Days Transformation Yoga Retreat "Yogyatra - A Journey Within" in Devbhoomi</h2>
              <p className="locationonline">24th January-29th January, 2025</p>
              <span className="package-tagonline">5 Nights, 6 Days Package</span>
              <ul>
                <li>Sacred Locations and High-Vibrational Temples</li>
                <li>Activities to Foster Self-Connection and Inner Travel</li>
                <li>Chakra Healing and Balance</li>
                <li>Deep Meditation Sessions and Techniques</li>
              </ul>
              <div className="course-footeronline">
                <p className="priceonline">Rs 24,999</p>
                <button
                  className="book-now-buttononline"
                  onClick={() => navigate('/contact')} // Unique handler for this course
                >
                  Contact us to book
                </button>
              </div>
            </div>
          </div>

          <section className="title-sectiononline">
        <h1>Upcoming Retreats</h1>
        </section>

        {/* Course 2 */}
        <div className="course-cardonline">
            <div className="course-imageonline">
              <img  loading='eager'    src={image2} alt="7 Days Private Retreat 'An Outward and Inward Journey' in Vietnam" preload="auto" />
            </div>
            <div className="course-detailsonline">
              <h2>6 Days Transformation Yoga Retreat "Yogyatra - A Journey Within" in Devbhoomi</h2>
              <p className="locationonline">14th March-19th March, 2025</p>
              <span className="package-tagonline">5 Nights, 6 Days Package</span>
              <ul>
                <li>Sacred Locations and High-Vibrational Temples</li>
                <li>Activities to Foster Self-Connection and Inner Travel</li>
                <li>Chakra Healing and Balance</li>
                <li>Deep Meditation Sessions and Techniques</li>
              </ul>
              <div className="course-footeronline">
                <p className="priceonline">Rs 24,999</p>
              </div>
            </div>
          </div>

          {/* Course 3 */}
        <div className="course-cardonline">
            <div className="course-imageonline">
              <img  loading='eager'  src={image3} alt="7 Days Private Retreat 'An Outward and Inward Journey' in Vietnam" preload="auto" />
            </div>
            <div className="course-detailsonline">
              <h2>6 Days Transformation Yoga Retreat "Yogyatra - A Journey Within" in Devbhoomi</h2>
              <p className="locationonline">April, May, June (Dates to be announced soon), 2025</p>
               <span className="package-tagonline">5 Nights, 6 Days Package</span>
              <ul>
                <li>Sacred Locations and High-Vibrational Temples</li>
                <li>Activities to Foster Self-Connection and Inner Travel</li>
                <li>Chakra Healing and Balance</li>
                <li>Deep Meditation Sessions and Techniques</li>
              </ul>
              <div className="course-footeronline">
                <p className="priceonline">Rs 24,999</p>
              </div>
            </div>
          </div>

          {/* Course 2 */}
          {/* <div className="course-cardonline">
            <div className="course-imageonline">
              <img src={image2} alt="5 Days Wellness Retreat 'Reconnect with Nature' in Bali" preload="auto" />
            </div>
            <div className="course-detailsonline">
              <h2>5 Days Wellness Retreat 'Reconnect with Nature' in Goa</h2>
              <p className="locationonline">15th Febuary-22nd Febuary, Goa</p>
              <span className="package-tagonline">5 Days Package</span>
              <ul>
                <li>5 Yoga Sessions, 3 Meditation Sessions, 2 Massage Treatments</li>
                <li>Guided nature walks included</li>
                <li>Small group setting (max 8 participants)</li>
                <li>Meals provided - Vegan, Vegetarian, Gluten-Free options</li>
              </ul>
              <div className="course-footeronline">
                <p className="priceonline">$1,500.00</p>
                <button
                  className="book-now-buttononline"
                  onClick={() => navigate('/bookgoa')} // Unique handler for this course
                >
                  Book Now
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Retreats;
