import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Explore.css';
import { useEffect } from 'react';
import image1 from './assets/rishi2.jpg';
import image2 from './assets/rishi7.jpg';
import image3 from './assets/rishi6.jpg';
import image4 from './assets/rishi5.jpg';
import image5 from './assets/rishi4.jpg';
// import image6 from './assets/map.jpg';

function Explorerishi() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  // State hooks to handle "Read More" and "Read Less" functionality
  // const [showDetails, setShowDetails] = useState(false);
  // const [showSchedule, setShowSchedule] = useState(false);

  // const toggleDetails = () => setShowDetails(!showDetails);
  // const toggleSchedule = () => setShowSchedule(!showSchedule);

  return (
    <div className="App">
      {/* Main Content */}
      <main>

        {/* Gallery Section */}
        <section className="gallery-sectionrishikesh">
          <div className="main-imagerishikesh">
            <img
              src={image3} 
              preload="auto" 
              alt="Main Retreat Image1"
              loading="eager" // Removed lazy loading
            />
            {/* <div className="main-textrishikesh">
              <h2>Yogyatra - A Journey Within</h2>
              <h2>Rishikesh</h2>
            </div> */}
          </div>

          <div className="thumbnail-galleryrishikesh">
            <img src={image1} preload="auto" alt="photo1" loading="eager" /> {/* Removed lazy loading */}
            <img src={image2} preload="auto" alt="photo2" loading="eager" /> {/* Removed lazy loading */}
            <img src={image4} preload="auto" alt="photo3" loading="eager" /> {/* Removed lazy loading */}
            <img src={image5} preload="auto" alt="photo4" loading="eager" /> {/* Removed lazy loading */}
          </div>
        </section>

        {/* Retreat Package Section */}
        <section className="retreat-packagerishikesh">
          <div className="retreat-imagerishikesh">
            <img src={image1} preload="auto" alt="Retreat in Vietnam" loading="eager" /> {/* Removed lazy loading */}
          </div>
          <div className="retreat-detailsrishikesh">
            <h3>6 Days Transformation Yoga Retreat "Yogyatra - A Journey Within" in Devbhoomi</h3>
            <p className="locationrishi">24th January-29th January, 2025</p>
            <button className="package-buttonrishikesh">5 Nights, 6 Days Package</button>
            <ul>
              <li>Sacred Locations and High-Vibrational Temples</li>
              <li>Activities to Foster Self-Connection and Inner Travel</li>
              <li>Chakra Healing and Balance</li>
              <li>Deep Meditation Sessions and Techniques</li>
            </ul>
            <div className="price-sectionrishikesh">
              <p>Rs 24,999</p>
              <button className="book-button" onClick={() => navigate('/contact')}>Contact us to book</button>
            </div>
          </div>
        </section>

        {/* New Retreat Details Section */}
        <section className="retreat-details-sectionrishikesh">
          <h3>Highlights</h3>
          <p>1.	Sacred Locations and High-Vibrational Temples<br></br>
	          2.	Holistic Yoga Practices<br></br>
	          3.	Chakra Healing and Balance<br></br>
	          4.	Deep Meditation Sessions and Techniques<br></br>
	          5.	Soulful Satvik Bhajans<br></br>
	          6.	Guided Self-Discovery<br></br>
	          7.	Mindful Community Building<br></br>
	          8.	Yogi Din Charya (A Day in the Life of a Yogi)<br></br>
	          9.	Traditional Yoga Philosophy with Modern Insight<br></br>
	          10.	Customized Experience for All Levels<br></br>
	          11.	Nature-Connected Practices<br></br>
	          12.	Activities to Foster Self-Connection and Inner Travel</p><br></br>
          <h3>Details of This Retreat</h3>
          <p>
          The detailed itinerary, including specific activities and sessions, will be provided closer to the experience date to preserve the essence of spontaneity and surprise in this divine journey.
          </p><br></br>
          <h3>Inclusion</h3>
          <p>1. Personalized wellness experience.<br></br>
            2. Accommodation on Double sharing basis.<br></br>
            3. Professionally curated Satvik meals.<br></br>
            4. Everyday Movement, yoga & healing sessions.<br></br>
            5. Planned travel to destinations in the<br></br>
            6. Experience itinerery
          </p><br></br>
          <h3>Exclusion</h3>
          <p>1. Flight/ train tickets<br></br>
            2. Airport transfers<br></br>
            3. Personal expense<br></br>
            4. Anything not mentioned in the inclusion
          </p>

          {/* {showDetails && (
            <p>
              Our retreat sites are dotted around Vietnam where you can find yourself high within the clouds and
              the mountains, or hidden away on a private tropical island paradise. We fully believe our locations
              highlight the stunning beauty of Vietnam and we are delighted to be able to share them with you.
              Our retreat sites are dotted around Vietnam where you can find yourself high within the clouds and
              the mountains, or hidden away on a private tropical island paradise. We fully believe our locations
              highlight the stunning beauty of Vietnam and we are delighted to be able to share them with you.
              Our retreat sites are dotted around Vietnam where you can find yourself high within the clouds and
              the mountains, or hidden away on a private tropical island paradise. We fully believe our locations
              highlight the stunning beauty of Vietnam and we are delighted to be able to share them with you.
            </p>
          )}
          <button onClick={toggleDetails}>
            {showDetails ? 'Read Less' : 'Read More'}
          </button>
        </section>

        {/* New Schedule Section 
        <section className="schedule-sectionrishikesh">
          <h3>Schedule</h3>
          <p>
            Hanoi 3 days: Hanoi is Vietnam’s capital city. Located by the banks of the Red River in the northern
            half of the country, it’s the second-largest city in Vietnam by population, behind only Saigon. 
            Our retreat sites are dotted around Vietnam where you can find yourself high within the clouds and
            the mountains, or hidden away on a private tropical island paradise.
          </p>
          {showSchedule && (
            <p>
              This location is a place for you to slow down, enjoy relaxing moments at hidden coffee, and immerse
              in truly Hanoi city vibe. You’ll find a lively cacophony of sounds, sights, and scents: the buzz of
              scooters speeding by, dynamic conversations between merchants, pretty colonial architecture, eye-catching
              outdoor murals, and the mouthwatering aroma of Vietnamese street food.
              Day 1 - This location is a place for you to slow down, enjoy relaxing moments at hidden coffee, and immerse
              in truly Hanoi city vibe. You’ll find a lively cacophony of sounds, sights, and scents: the buzz of
              scooters speeding by, dynamic conversations between merchants, pretty colonial architecture, eye-catching
              outdoor murals, and the mouthwatering aroma of Vietnamese street food.
              Day 2 - This location is a place for you to slow down, enjoy relaxing moments at hidden coffee, and immerse
              in truly Hanoi city vibe. You’ll find a lively cacophony of sounds, sights, and scents: the buzz of
              scooters speeding by, dynamic conversations between merchants, pretty colonial architecture, eye-catching
              outdoor murals, and the mouthwatering aroma of Vietnamese street food.
            </p>
          )}
          <button onClick={toggleSchedule}>
            {showSchedule ? 'Read Less' : 'Read More'}
          </button> */}
        </section>
        {/* <section className="locations-sectionrishikesh">
            <h3>Locations We Will Visit</h3>
            <div className="locations-imagerishikesh">
              <img src={image6} preload="auto" alt="Retreat Location" />
            </div>
        </section> */}
      </main>
    </div>
  );
}

export default Explorerishi;
