import React, { useState } from 'react';
import './Readmore.css';
import { useEffect } from 'react';
import video1 from './assets/govar1.mp4';
import video2 from './assets/govar2.mp4';
import image1 from './assets/govar1.JPG';
import image2 from './assets/govar2.JPG';
import image3 from './assets/govar3.JPG';
import image4 from './assets/govar4.JPG';
import rev7 from './assets/rev7.jpeg';
//import rev8 from './assets/rev8.jpeg';
import rev9 from './assets/rev9.png';
import try6 from './assets/try6.png';
import i1 from './assets/GOVARDHAN1.PNG';
import i2 from './assets/GOVARDHAN2.PNG';


function Readmore3() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Set an interval to change the current image every 4 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 4000); // 4 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const videos = [video1, video2];
  // Testimonial data
  const testimonials = [
    {
      name: "Pallavi Bhattad",
      work: "CA",
      title: "Highly Fulfilling",
      body: "The yoga and meditation sessions were life-changing. I'll be back!",
      image: rev7,
    },
    {
      name: "Janice",
      work: "Entrepreneur, Philippines",
      title: "Transformative Experience",
      body: "I felt a deep connection to myself and nature. Beautiful atmosphere!",
      image: try6,
    },
    {
      name: "Bridget",
      work: "CEO at Wonki",
      title: "Mind and Body Bliss",
      body: "This retreat offered perfect rejuvenation for my mind and body.",
      image: rev9,
    },
  ];

  // Carousel images
  const carouselImages = [
    image1,image2,image3,image4,
  ];

  // State to keep track of the current carousel image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Utility function to detect touch devices
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  // Video interaction handler for click (used for touch devices)
  const handleVideoClick = (e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // Handler for moving to the next image
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  // Handler for moving to the previous image
  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="App">

      {/* Main Content */}
      <main>
        {/* Title Section */}
        <section className="title-section">
          <h1>Govardhan Ecovillage</h1>
        </section>
          
          <section>
          {/* Carousel */}
          <div className="carousel">
            <button className="carousel-button prev" onClick={goToPreviousSlide}>❮</button>
            <img
              src={carouselImages[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-button next" onClick={goToNextSlide}>❯</button>
          </div>
        </section>

        {/* Description Section */}
        <section className="description-carousel-section">
          <div className="description"><br></br><br></br>
            <p>
            At Yogyatra, our retreat at Govardhan Ecovillage has been a beautiful blend of yoga, sustainability, and holistic living. Set in the serene countryside of Maharashtra, Govardhan Ecovillage offers an ideal environment for our guests to reconnect with nature and explore inner peace. Surrounded by lush greenery, organic farms, and tranquil waters, participants have embarked on transformative journeys guided by skilled instructors. From morning yoga sessions amidst the natural landscape to evening reflections under the open sky, every experience is designed to rejuvenate both mind and body. Our retreats at Govardhan Ecovillage reflect Yogyatra’s deep commitment to creating spaces of wellness, sustainability, and spiritual harmony.
            </p>
          </div>
          </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-cards">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="stars">★★★★★</div>
                <h3>{testimonial.title}</h3>
                <p>{testimonial.body}</p>
                <div className="reviewer-info">
                  <img src={testimonial.image} alt={`${testimonial.name}'s profile`} />
                  <div>
                    <p>{testimonial.name}</p>
                    <p>{testimonial.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <section className="gallery">
  {videos.map((video, index) => (
    <div key={index} className="gallery-item">
      <video
        src={video}
        className="gallery-video"
        loop
        playsInline
        poster={isTouchDevice() ? (index === 0 ? i1 : i2) : undefined}
        onClick={handleVideoClick}
        // Ensure video plays on hover for non-touch devices (desktop)
        onMouseOver={!isTouchDevice() ? (e) => e.currentTarget.play() : undefined}
        onMouseOut={!isTouchDevice() ? (e) => e.currentTarget.pause() : undefined}
      />
    </div>
  ))}
</section>
        </section>
      </main>
    </div>
  );
}

export default Readmore3;