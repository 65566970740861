import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from './assets/logo.png';
import backgroundAudio from './assets/flute.mp3'; // Import your audio file
import Gallery from './Gallery';
import Onlinecourse from './Onlinecourse';
import Contact from './Contact';
import Home from './Home';
import About from './About';
import Profile from './Profile';
import Explorerishi from './Explorerishi';
//import Exploregoa from './Exploregoa';
import Readmore1 from './Readmore1';
import Readmore2 from './Readmore2';
import Readmore3 from './Readmore3';
import Readmore4 from './Readmore4';
import Readmore5 from './Readmore5';
import Readmore6 from './Readmore6';
// import Booking from './Booking';
import Retreats from './Retreats';
import Rishivideo from './Rishivideo';
import Privacypolicy from './Privacypolicy';
import Disclaimer from './Disclaimer';
import Shippingpol from './Shippingpol';
import Refundpol from './Refundpol';
import Terms from './Terms';

function App() {
  const videoRef = useRef(null);
  const audioRef = useRef(null); // Reference to the audio element
  const [showOverlay, setShowOverlay] = useState(false);

  const handleVideoEnd = () => {
    setShowOverlay(true);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.play().catch((error) => {
        console.error('Autoplay audio failed:', error);
      });
    }
  }, []);

  return (
    <Router>
      <div className="App">
        {/* Navigation Bar */}
        <header className="navbar">
          <div className="navbar-logo-container">
            <img src={logo} alt="Yogyatra Logo" className="navbar-logo-image" />
            <div className="navbar-logo">Yog Yatra</div>
          </div>
          <nav>
            <Link to="/home" className="nav-btn">Home</Link>
            <Link to="/about" className="nav-btn">About</Link>
            <Link to="/retreats" className="nav-btn">Retreats</Link>
            <Link to="/gallery" className="nav-btn">Gallery</Link>
            <Link to="/profile" className="nav-btn">Profile</Link>
            <Link to="/Onlinecourse" className="nav-btn">Online Course</Link>
            <Link to="/contact" className="nav-btn">Contact Us</Link>
          </nav>
          {/* Updated Book Now Button */}
          <BookNowButton />
        </header>

        {/* Define Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                videoRef={videoRef}
                audioRef={audioRef}
                handleVideoEnd={handleVideoEnd}
                showOverlay={showOverlay}
              />
            }
          />
          <Route path="/home" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/explorerishi" element={<Explorerishi />} />
           {/* <Route path="/exploregoa" element={<Exploregoa />} />  */}
          <Route path="/readmore1" element={<Readmore1 />} />
          <Route path="/readmore2" element={<Readmore2 />} />
          <Route path="/readmore3" element={<Readmore3 />} />
          <Route path="/readmore4" element={<Readmore4 />} />
          <Route path="/readmore5" element={<Readmore5 />} />
          <Route path="/readmore6" element={<Readmore6 />} />
          <Route path="/Onlinecourse" element={<Onlinecourse />} />

          {/* <Route path="/bookrishi" element={<Booking />} /> */}
          {/* <Route path="/bookgoa" element={<Booking />} /> */}
          <Route path="/retreats" element={<Retreats />} />
          <Route path="/rishivideo" element={<Rishivideo />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/shipping-policy" element={<Shippingpol />} />
          <Route path="/refund-policy" element={<Refundpol />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

// Landing Page Component
function LandingPage({ videoRef, audioRef, handleVideoEnd, showOverlay }) {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/home');
  };

  return (
    <section className="hero-section">
      <div className="video-container">
        <audio ref={audioRef} src={backgroundAudio} loop autoPlay />
        <video
          ref={videoRef}
          src="path_to_your_video (1) (1) .mp4" // Replace with actual path
          type="video/mp4"
          autoPlay
          muted
          playsInline
          preload="auto" // Ensures the video is loaded as soon as possible
          className="background-video"
          onEnded={handleVideoEnd}
        ></video>
        <div className={`overlay ${showOverlay ? 'fade-in' : 'fade-out'}`}>
          <h1 className="welcome-text">Welcome to Yog Yatra</h1>
          <h3 className="tagline">A Journey Within</h3>
          <button className="get-started-btn" onClick={handleGetStarted}>
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
}

// Separate Component for Book Now Button with Dropdown
function BookNowButton() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const handleBookNowClick = () => {
    setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
  };

  const handleOptionClick = (path) => {
    setDropdownVisible(false); // Hide dropdown after selecting an option
    navigate(path); // Navigate to the selected route
  };

  return (
    <div className="book-now-container">
      <button className="book-now-btn" onClick={handleBookNowClick}>
        BOOK YOUR YATRA
      </button>
      {dropdownVisible && (
        <div className="dropdown">
          <button
            className="dropdown-option"
            onClick={() => handleOptionClick('/explorerishi')}
          >
            Book Rishikesh Retreat
          </button>
          {/* <button
            className="dropdown-option"
            onClick={() => handleOptionClick('/exploregoa')}
          >
            Book Goa Retreat
          </button> */}
        </div>
      )}
    </div>
  );
}

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="contact-info">
        <a href="https://www.instagram.com/yogini_saloni">
          <FontAwesomeIcon icon={faInstagram} className="social-icon"  style={{color:'darkgreen'}}/>
          @yogini_saloni
        </a>
        <a href="https://wa.me/+917798925709">
          <FontAwesomeIcon icon={faWhatsapp} className="social-icon" style={{color:'darkgreen'}}/>
          <span>+917798925709</span>
        </a>
        <a href="mailto:contact@yogyatra.org">
          <FontAwesomeIcon icon={faEnvelope} className="social-icon"  style={{color:'darkgreen'}} />
          contact@yogyatra.org
        </a>
      </div>
      <p>
        © Copyright {currentYear} Yogyatra |{' '}
        <a href="/privacy-policy" className="footer-link">Privacy Policy</a> |{' '}
        <a href="/terms" className="footer-link">Terms Of Use</a> |{' '}
        <a href="/disclaimer" className="footer-link">Disclaimer</a> |{' '}
        <a href="/shipping-policy" className="footer-link">Shipping Policy</a> |{' '}
        <a href="/refund-policy" className="footer-link">Refund Policy</a>
      </p>
    </footer>
  );
};

export default App;
