import React, { useEffect } from 'react';
import './Profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import profile from './assets/mamphoto.jpg';

const Profile = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="profile-section">
        <div className="profile-card">
          <img
            src={profile}
            preload="auto"
            alt="Saloni Rajawat"
            className="profile-image"
          />
          <div className="profile-content">
            <h2>PROFILE</h2>
            <p>
              With each sun salutation and each meditation, my connection to the world deepens. As I roll out my mat, I am not just performing a routine; I am entering a sanctuary, a place where I rediscover myself. Through countless poses and breaths, I learned that yoga is not just about flexibility of the body but the adaptability of the mind. It gifted me patience during challenging moments and empathy for others' journeys. But my heart yearned for something more. I realized that the unity and compassion I found within needed to extend beyond the mat. That's when my mission was born. I set out to bring the transformative power of yoga to others, to create a ripple effect of wellness and mindfulness. Armed with scientific research that backs the myriad benefits of yoga, I embarked on a journey to teach, to inspire, and to heal. So here I am, introducing myself as Saloni a Yog Abhyasi whose life has been touched and forever changed by the practice. Now, I invite you to join me on this adventure, to explore the boundless potential of yoga, and to be a part of a movement towards a brighter, more harmonious world. Namaste. 🙏
            </p>
            <div className="social-links">
              <a href="https://www.instagram.com/yogini_saloni" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                @yogini_saloni
              </a>
              <a href="https://www.linkedin.com/in/salonirajawat" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                Saloni Rajawat
              </a>
              <a href="mailto:contact@yogyatra.org">
                <FontAwesomeIcon icon={faEnvelope} className="social-icon" />
                contact@yogyatra.org
              </a>
            </div>
            <h3>Saloni Rajawat</h3>
            <h5>Certified Yoga trainer- ASHTANGA & HATHA YOG</h5>
            <h5>Certified Reiki Healer</h5>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
