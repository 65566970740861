import React, { useState, useEffect } from 'react';
import './Gallery.css';
import image1 from './assets/gal12.JPG';
import image2 from './assets/2.jpg';
import image3 from './assets/3.jpg';
import image4 from './assets/4.jpg';
import image5 from './assets/5.jpg';
import image6 from './assets/gal9.JPG';
import image7 from './assets/7.jpg';
import image8 from './assets/8.jpg';
import image9 from './assets/9.jpg';
import image10 from './assets/10.jpg';
import image11 from './assets/11.jpg';
import image12 from './assets/12.jpg';
import image13 from './assets/gal1.JPG';
import image14 from './assets/gal7.JPG';
import image15 from './assets/gal3.JPG';
import image16 from './assets/gal4.JPG';
import image17 from './assets/gal11.jpg';
import image18 from './assets/gal10.JPEG';
import image19 from './assets/gal8.JPG';
import image20 from './assets/gal6.JPG';

function Gallery() {
  useEffect(() => {
    // Scroll to top when the component loads
    window.scrollTo(0, 0);
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleImages, setVisibleImages] = useState(12);

  // Array of image sources
  const images = [
    image1, image2, image3, image4, image5, image6, image7, image8,
    image9, image10, image11, image12, image13, image14, image15, image16,
    image17, image18, image19, image20
  ];

  // Function to load more images
  const loadMoreImages = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 12);
  };

  // Open modal and set the current image index
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalOpen(false);
  };

  // Navigate to the previous image
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Navigate to the next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="Gallery">
      {/* Hero section */}
      <section className="hero">
        <h1>GALLERY</h1>
      </section>

      {/* Gallery section */}
      <section className="gallery">
        <div className="gallery-content">
          <div className="gallery-grid">
            {/* Display visible images */}
            {images.slice(0, visibleImages).map((src, index) => (
              <div key={index} className="gallery-item" onClick={() => openModal(index)}>
                <picture preload="auto">
                  <source srcSet={src} type="image/jpeg" preload="auto" />
                  <img src={src} alt={`Gallery item ${index + 1}`} preload="auto" />
                </picture>
              </div>
            ))}
          </div>
          {/* "See More" button */}
          {visibleImages < images.length && (
            <div className="see-more-container">
              <button className="see-more" onClick={loadMoreImages}>
                See More
              </button>
            </div>
          )}
        </div>
      </section>

      {/* Modal for displaying larger images */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {/* Close button */}
            <span className="close-modal" onClick={closeModal}>&times;</span>
            {/* Previous image button */}
            <button className="prev-button" onClick={prevImage}>&#10094;</button>
            <picture preload="auto">
              <source srcSet={images[currentImageIndex]} type="image/jpeg" preload="auto" />
              <img
                src={images[currentImageIndex]}
                alt={`Modal item ${currentImageIndex + 1}`}
                className="modal-image"
                preload="auto"
              />
            </picture>
            {/* Next image button */}
            <button className="next-button" onClick={nextImage}>&#10095;</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;
