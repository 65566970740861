import React, { useState } from 'react';
import './Readmore.css';
import { useEffect } from 'react';
import video1 from './assets/mum1.mp4';
import video2 from './assets/mum2.mp4';
import image1 from './assets/mum1.jpg';
import image2 from './assets/mum2.jpg';
import image3 from './assets/mum3.jpg';
import image4 from './assets/mum4.jpg';
//import rev16 from './assets/rev16.jpeg';
import rev17 from './assets/rev17.jpeg';
import rev18 from './assets/rev18.jpeg';
import try7 from './assets/try7.png';

import i1 from './assets/READ6.png';
import i2 from './assets/READ66.png';


function Readmore6() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Set an interval to change the current image every 4 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 4000); // 4 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const videos = [video1, video2];
  // Testimonial data
  const testimonials = [
    {
      name: "Venkat",
      work: "Business Man",
      title: "Beyond Expectations",
      body: "Exceeded all my expectations. The perfect place for peace and relaxation.",
      image: try7,
    },
    {
      name: "Kashish Bhatia",
      work: "Actor",
      title: "Revitalising Experience",
      body: "This retreat brought a new level of revitalization to my life.",
      image: rev17,
    },
    {
      name: "Akanksha",
      work: "Marketing Head",
      title: "Deeply Nourishing",
      body: "From the yoga to the food, everything was nourishing and heartfelt.",
      image: rev18,
    },
  ];

  // Carousel images
  const carouselImages = [
    image1,image2,image3,image4,
  ];

  // State to keep track of the current carousel image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Utility function to detect touch devices
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  // Video interaction handler for click (used for touch devices)
  const handleVideoClick = (e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // Handler for moving to the next image
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  // Handler for moving to the previous image
  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="App">

      {/* Main Content */}
      <main>
        {/* Title Section */}
        <section className="title-section">
          <h1>Mumbai</h1>
        </section>
          
          <section>
          {/* Carousel */}
          <div className="carousel">
            <button className="carousel-button prev" onClick={goToPreviousSlide}>❮</button>
            <img
              src={carouselImages[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-button next" onClick={goToNextSlide}>❯</button>
          </div>
        </section>

        {/* Description Section */}
        <section className="description-carousel-section">
          <div className="description"><br></br><br></br>
            <p>
            At Yogyatra, our retreat in Mumbai offered a unique blend of urban energy and mindful relaxation. Set against the vibrant backdrop of India's bustling metropolis, the retreat served as a transformative escape for corporate professionals looking to reconnect with themselves amidst their busy schedules. In the midst of Mumbai's fast-paced environment, our specially designed session provided an oasis of calm and focus. Guided by experienced instructors, participants engaged in a dynamic combination of yoga and mindfulness practices, designed to reduce stress, improve concentration, and enhance well-being. With the city’s skyline as a backdrop, the session offered a refreshing break from daily routines, fostering clarity, balance, and a renewed sense of purpose. At Yogyatra we are committed to creating meaningful experiences that promote both professional growth and personal wellness, helping individuals cultivate peace within while thriving in the heart of the city.
            </p>
          </div>
          </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-cards">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="stars">★★★★★</div>
                <h3>{testimonial.title}</h3>
                <p>{testimonial.body}</p>
                <div className="reviewer-info">
                  <img src={testimonial.image} alt={`${testimonial.name}'s profile`} />
                  <div>
                    <p>{testimonial.name}</p>
                    <p>{testimonial.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

     {/* Gallery Section */}
     <section className="gallery">
  {videos.map((video, index) => (
    <div key={index} className="gallery-item">
      <video
        src={video}
        className="gallery-video"
        loop
        playsInline
        poster={isTouchDevice() ? (index === 0 ? i1 : i2) : undefined}
        onClick={handleVideoClick}
        // Ensure video plays on hover for non-touch devices (desktop)
        onMouseOver={!isTouchDevice() ? (e) => e.currentTarget.play() : undefined}
        onMouseOut={!isTouchDevice() ? (e) => e.currentTarget.pause() : undefined}
      />
    </div>
  ))}
</section>
        </section>
      </main>
    </div>
  );
}

export default Readmore6;