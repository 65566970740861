import React, { useState, useEffect } from "react";
import "./Onlinecourse.css";
//import { useNavigate } from 'react-router-dom';
import image1 from "./assets/gal6.JPG";



function Onlinecourse() {
  //const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false); // Modal state

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handleKnowMore = () => {
    setShowModal(true); // Show modal on button click
  };

  const closeModal = () => {
    setShowModal(false); // Close modal
  };

  return (
    <div className="App">
      {/* Title Section */}
      <section className="title-sectiononline">
        <h1>Online Courses</h1>
      </section>

      {/* Courses Section */}
      <section className="courses-sectiononline">
        <div className="courses-gridonline">
          {/* Course 1 */}
          <div className="course-cardonline">
            <div className="course-imageonline">
              <img
                src={image1}
                alt="5 Days Private Retreat 'An Outward and Inward Journey' in Vietnam"
              />
            </div>
            <div className="course-detailsonline">
              <h2>5 Days Online Wellness Retreat 'An Outward and Inward Journey'</h2>
              <p className="locationonline">
                This online yoga program is a holistic journey into yoga,
                curated especially for beginners who wish to take their first
                steps into a life of wellness and mindfulness. From asanas to
                philosophy, you’ll learn it all in a structured and
                beginner-friendly way.
              </p>
              {/* <span className="package-tagonline">5 Days Package</span> */}
              <ul>
                <li>Beginner-friendly approach</li>
                <li>5 days a week live sessions</li>
                <li>Recorded sessions provided for missed classes</li>
                <li>Affordable price</li>
              </ul>
              <div className="course-footeronline">
                <p className="priceonline">Rs.1500.00</p>
                <button
                  className="book-now-buttononline"
                  onClick={handleKnowMore} // Show modal on click
                >
                  Know More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal Section */}
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content scrollable-modal"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
          >
            {/* Modal Header */}
            <button className="modal-close-button" onClick={closeModal}>
              ✖
            </button>

            {/* Title and Price */}
            <h2 className="modal-title">Yog Yatra Online Classes</h2>
            <p className="modal-price">₹ 1500 | Jan 06 - Jan 27 | 5 Weeks Total</p>

            {/* Schedule Section */}
            <div>
              <h3 className="section-title">Weekly Schedule</h3>
              <div className="schedule-list">
                <p className="schedule-list-item">
                  <strong>Day 1:</strong> Foundations of Yoga<br />
                  Introduction to basic asanas (postures)<br></br>
                  Understanding the essence of yoga <br></br>
                  Breathing techniques (Pranayam basics)
                </p>
                <p className="schedule-list-item">
                  <strong>Day 2:</strong> Pranayam & Meditation<br />
                  Deep dive into breathing techniques<br></br>
                  Guided meditation for mental clarity and calmness
                </p>
                <p className="schedule-list-item">
                  <strong>Day 3:</strong> Introduction to Ayurveda<br />
                  Simple Ayurvedic tips for daily living <br></br>
                  Understanding doshas and their impact on health
                </p>
                <p className="schedule-list-item">
                  <strong>Day 4:</strong> Yoga for Strength & Flexibility<br />
                  Beginner-friendly poses to build strength and increase flexibility <br></br>
                  Importance of mindfulness during practice
                </p>
                <p className="schedule-list-item">
                  <strong>Day 5:</strong> Yoga Philosophy Simplified<br />
                  Exploring the basics of yoga philosophy <br></br>
                  Q&A session to clarify doubts
                </p>
              </div>
            </div>

            {/* Workshop Details */}
            <div>
              <h3 className="section-title">🌿 Benefits of Joining</h3>
              <p className="workshop-details">
                Build strength, flexibility, and stamina <br></br>
                Learn techniques to manage stress and anxiety <br></br>
                Understand Ayurveda for a healthier lifestyle <br></br>
                Experience mindfulness through yoga and meditation <br></br>
                Gain a strong foundation in yoga and its philosophy
              </p>
            </div>

            {/* Booking Section */}
            <div className="booking-section">
              <div className="booking-info">
                {/* <p>
                  Booking For<br />
                  <strong>1 user</strong><br /><br />
                </p> */}
                <p>
                  Time Zone<br />
                  <strong>India (05:30pm)</strong><br /><br />
                </p>
                <p>
                  1 User Payable Amount<br />
                  <strong>₹ 1500</strong>
                </p>
              </div>
            </div>

            {/* Book a Spot Button */}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button className="booking-button"   onClick={() => window.location.href = 'https://wa.me/+917798925709?text=I%20would%20like%20to%20book%20a%20spot%20for%20the%20Yog%20Yatra%20Online%20Classes%20program.'}>BOOK A SPOT →</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Onlinecourse;
