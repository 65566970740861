import React, { useState, useEffect } from 'react';
import './Contact.css';

function Contacts() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    text: '',
    query: '',
  });

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Open mail client with dynamically filled email
    const mailtoLink = `mailto:contact@yogyatra.org?subject=${formData.query} - Contact Query from ${formData.firstName} ${formData.lastName}&body=Hi Yogyatra Team,%0D%0A%0D%0A${formData.text}%0D%0A%0D%0AName: ${formData.firstName} ${formData.lastName}%0D%0AEmail: ${formData.email}%0D%0A%0D%0AThank you!`;
    window.location.href = mailtoLink;

    // Reset form fields
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      text: '',
      query: '',
    });
  };

  return (
    <div className="App">
      <section className="contact-section">
        <h1>Contact Us</h1><br />
        <div className="contact-section">
          <p className="contact-subtitle">We’re here for you whenever you need us!</p>
          <p className="contact-text">
            At Yogyatra, the well-being of our guests is our top priority. Our commitment to care extends beyond your time with us.
            If there's anything we can support you with, please don't hesitate to reach out. We are here to assist you in any way that suits you best.
          </p>
        </div>
        <div className="contact-info">
          <div className="contact-item">
            <a href="https://wa.me/+917798925709" target="_blank" rel="noopener noreferrer">
              <img src="./phone_icon.png" alt="Phone Icon" /><br />
              <span>+917798025709</span>
            </a>
          </div>
          <div className="contact-item">
            <a href="mailto:contact@yogyatra.org">
              <img src="./email_icon.png" alt="Email Icon" />
              <span>contact@yogyatra.org</span>
            </a>
          </div>
          <div className="contact-item">
            <a href="https://instagram.com/yogini_saloni" target="_blank" rel="noopener noreferrer">
              <img src="./instagram_icon.png" alt="Instagram Icon" /><br />
              <span>@yogini_saloni</span>
            </a>
          </div>
        </div>
        <form className="enquiry-form" onSubmit={handleSubmit}>
          <h2>Enquire</h2>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <select
            name="query"
            value={formData.query}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>Select your query</option>
            <option value="Rishikesh Retreat">Rishikesh Retreat</option>
            <option value="Online Course">Online Course</option>
          </select>
          <textarea
            type="text"
            placeholder="Message"
            name="text"
            rows={4}
            value={formData.text}
            onChange={handleInputChange}
            required
          /> <br />
          <button type="submit" className="submit-btn">
            Submit
          </button><br />
        </form>
      </section>
    </div>
  );
}

export default Contacts;