import React from 'react';

function Disclaimer() {
  return (
    <div><br></br>
      <h2>Disclaimer</h2>
      <p>
      Yogyatra (“us”, “we”, or “our”) operates the yogyatra.org website (the “Service”). <br></br>

ANY BENEFITS, RESULTS, OR EXPERIENCES SHARED, OR EXAMPLES OF BENEFITS, RESULTS, OR EXPERIENCES, ARE ONLY ESTIMATES OF WHAT WE BELIEVE YOU COULD ACHIEVE. THERE IS NO ASSURANCE YOU'LL EXPERIENCE THE SAME OUTCOME. IF YOU RELY UPON OUR EXAMPLES, YOU MUST ACCEPT THE RISK OF HAVING A DIFFERENT EXPERIENCE. <br></br> <br></br>

WHERE SPECIFIC BENEFITS OR TESTIMONIALS ARE USED AND ATTRIBUTED TO AN INDIVIDUAL OR GROUP, THOSE EXPERIENCES BELONG TO THOSE PERSONS OR GROUPS. THERE IS NO ASSURANCE YOU WILL EXPERIENCE THE SAME RESULTS. IF YOU RELY UPON OUR EXAMPLES, YOU MUST ACCEPT THE RISK OF A DIFFERENT OUTCOME. <br></br> <br></br>

ANY AND ALL CLAIMS OR REPRESENTATIONS, AS TO TRANSFORMATIVE OR WELLNESS BENEFITS ON THIS WEBSITE, ARE NOT TO BE CONSIDERED TYPICAL. TESTIMONIALS ARE NOT NECESSARILY REPRESENTATIVE. <br></br> <br></br>

THERE CAN BE NO ASSURANCE THAT ANY PRIOR SUCCESS STORIES OR EXPERIENCES CAN BE USED AS AN INDICATION OF YOUR FUTURE SUCCESS OR OUTCOME. <br></br> <br></br>

THE BENEFITS OR RESULTS YOU EXPERIENCE MAY DEPEND ON MANY FACTORS. WE HAVE NO WAY OF KNOWING HOW YOU WILL BENEFIT, AS WE DO NOT KNOW YOUR BACKGROUND, COMMITMENT, PHYSICAL CONDITION, OR PERSONAL INTENTIONS. THEREFORE, WE DO NOT GUARANTEE OR IMPLY THAT YOU WILL ACHIEVE ANY SPECIFIC OUTCOMES OR BENEFITS. IF YOU RELY UPON OUR EXAMPLES, YOU MUST ACCEPT THE RISK OF EXPERIENCING DIFFERENT RESULTS. <br></br> <br></br>

PARTICIPATING IN YOGA RETREATS OR SIMILAR WELLNESS PROGRAMS INVOLVES UNKNOWN RISKS AND MAY NOT BE SUITABLE FOR EVERYONE. MAKING DECISIONS BASED ON ANY INFORMATION PRESENTED IN OUR PROGRAMS, SERVICES, OR WEBSITE SHOULD BE DONE ONLY WITH THE KNOWLEDGE THAT YOU COULD EXPERIENCE UNEXPECTED RESULTS OR NO RESULTS AT ALL. ONLY PARTICIPATE IF YOU ARE COMFORTABLE TAKING THIS RISK. <br></br> <br></br>

ALL PROGRAMS AND SERVICES PROVIDED BY OUR COMPANY ARE FOR EDUCATIONAL, WELLNESS, AND INFORMATIONAL PURPOSES ONLY. USE CAUTION AND SEEK THE ADVICE OF QUALIFIED PROFESSIONALS. CONSULT YOUR DOCTOR, THERAPIST, OR PROFESSIONAL ADVISOR BEFORE ENGAGING IN ANY PROGRAM OR ACTING ON THIS OR ANY INFORMATION. <br></br> <br></br>

USERS OF OUR PROGRAMS, SERVICES, AND WEBSITE ARE ADVISED TO CONDUCT THEIR OWN DUE DILIGENCE WHEN IT COMES TO MAKING DECISIONS. ALL INFORMATION, PROGRAMS, AND SERVICES THAT HAVE BEEN PROVIDED SHOULD BE INDEPENDENTLY VERIFIED BY YOUR OWN QUALIFIED PROFESSIONALS. OUR INFORMATION, PROGRAMS, AND SERVICES SHOULD BE CAREFULLY CONSIDERED AND EVALUATED BEFORE MAKING ANY DECISION TO RELY ON THEM. ALL DISCLOSURES AND DISCLAIMERS MADE HEREIN OR ON OUR SITE APPLY EQUALLY TO ANY OFFERS, PROMOTIONS, OR INCENTIVES THAT MAY BE PROVIDED BY OUR COMPANY. <br></br> <br></br>

YOU AGREE THAT OUR COMPANY IS NOT RESPONSIBLE FOR THE SUCCESS OR FAILURE OF YOUR DECISIONS RELATED TO ANY INFORMATION PRESENTED BY OUR COMPANY, OR OUR COMPANY’S PROGRAMS OR SERVICES. <br></br>
      </p>
    </div>
  );
}

export default Disclaimer;