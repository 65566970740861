import React, { useState } from 'react';
import './Readmore.css';
import { useEffect } from 'react';
import video1 from './assets/idk1.mp4';
import video2 from './assets/idk2.mp4';
import image1 from './assets/jaipur1.JPG';
import image2 from './assets/jaipur2.JPG';
import image3 from './assets/jaipur3.jpg';
import image4 from './assets/jaipur4.JPG';
import rev13 from './assets/rev13.jpeg';
import rev14 from './assets/rev14.jpeg';
import rev15 from './assets/rev15.jpeg';

import i1 from './assets/READ5.PNG';
import i2 from './assets/READ55.PNG';


function Readmore5() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Set an interval to change the current image every 4 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 4000); // 4 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const videos = [video1, video2];
  // Testimonial data
  const testimonials = [
    {
      name: "Amita Shah",
      work: "Entrepreneur",
      title: "Perfect Reset",
      body: "Exactly what I needed to reset and reconnect with my true self.",
      image: rev13,
    },
    {
      name: "Neha Madan",
      work: "Marketing Head",
      title: "Total Serenity",
      body: "The tranquil setting and yoga sessions brought me total serenity.",
      image: rev14,
    },
    {
      name: "Chhaya Jadega",
      work: "Homemaker",
      title: "Refreshing Retreat",
      body: "Each day felt like a fresh start. Truly refreshing for the soul.",
      image: rev15,
    },
  ];

  // Carousel images
  const carouselImages = [
    image1,image2,image3,image4,
  ];

  // State to keep track of the current carousel image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Utility function to detect touch devices
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  // Video interaction handler for click (used for touch devices)
  const handleVideoClick = (e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // Handler for moving to the next image
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  // Handler for moving to the previous image
  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="App">

      {/* Main Content */}
      <main>
        {/* Title Section */}
        <section className="title-section">
          <h1>Jaipur</h1>
        </section>
          
          <section>
          {/* Carousel */}
          <div className="carousel">
            <button className="carousel-button prev" onClick={goToPreviousSlide}>❮</button>
            <img
              src={carouselImages[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-button next" onClick={goToNextSlide}>❯</button>
          </div>
        </section>

        {/* Description Section */}
        <section className="description-carousel-section">
          <div className="description"><br></br><br></br>
            <p>
            At Yogyatra, our one-day retreat in Jaipur was a vibrant blend of yoga, mindfulness, and cultural immersion. Set against the backdrop of the Pink City’s majestic palaces and forts, Jaipur provided a unique space for our guests to reconnect with themselves in a regal and peaceful atmosphere. Amidst the rich heritage and desert breeze, participants embarked on a journey of self-discovery led by expert instructors. From morning yoga sessions in lush palace gardens to mindful meditations as the sun set behind ancient walls, every moment was designed to harmonize body and mind. This retreat captured Yogyatra’s vision of bringing wellness, community, and inner balance into every experience, even in the heart of Jaipur’s vibrant culture.
            </p>
          </div>
          </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-cards">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="stars">★★★★★</div>
                <h3>{testimonial.title}</h3>
                <p>{testimonial.body}</p>
                <div className="reviewer-info">
                  <img src={testimonial.image} alt={`${testimonial.name}'s profile`} />
                  <div>
                    <p>{testimonial.name}</p>
                    <p>{testimonial.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

      {/* Gallery Section */}
      <section className="gallery">
  {videos.map((video, index) => (
    <div key={index} className="gallery-item">
      <video
        src={video}
        className="gallery-video"
        loop
        playsInline
        poster={isTouchDevice() ? (index === 0 ? i1 : i2) : undefined}
        onClick={handleVideoClick}
        // Ensure video plays on hover for non-touch devices (desktop)
        onMouseOver={!isTouchDevice() ? (e) => e.currentTarget.play() : undefined}
        onMouseOut={!isTouchDevice() ? (e) => e.currentTarget.pause() : undefined}
      />
    </div>
  ))}
</section>
        </section>
      </main>
    </div>
  );
}

export default Readmore5;