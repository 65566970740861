import React, { useState, useEffect } from "react";
import "./About.css";
import image1 from './assets/rishi1.jpg';
import image2 from './assets/rishi2.jpg';
import image3 from './assets/goa3.jpg';
import image4 from './assets/vado1.JPEG';
import image5 from './assets/rishikeshcard.png';
import image6 from './assets/goa4.jpg';

const About = () => {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
    
  }, []);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [image1, image2, image3
  ];

  const images2 = [image4, image5, image6
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 4000); // Change image every 3 seconds
    const interval2 = setInterval(() => {
        setCurrentImage((prev) => (prev + 1) % images2.length);
      }, 4000);
    return () => {
        clearInterval(interval);
        clearInterval(interval2);
    }
  }, [images.length, images2.length]);

  return (
    <div className="about-container">
        {/* Title Section */}
        <section className="title-section">
            <h1>About Yogyatra</h1>
        </section>

      <blockquote>
        “Yoga takes you into the present moment. The only place where life exists.”
      </blockquote>

      <div className="about-content2">
        <div className="carousel2">
          <img loading="eager" src={images[currentImage]} alt={`Slide ${currentImage + 1}`} />
        </div>

        <div className="about-text2">
          <h3>Yog Yatra: A Journey Within</h3>
          <p>
          Yog Yatra is more than just a name; it embodies the essence of 
          what yoga truly represents—a profound, inward journey to the 
          divine and one’s innermost self. Founded by Saloni Rajawat, an 
          experienced international yoga trainer and yogapreneur, Yog Yatra 
          is the manifestation of her vision to make the transformative power 
          of yoga accessible to all. This initiative was born from a belief 
          that yoga transcends asanas and is a gateway to deeper self-discovery 
          and divine connection.
          </p><br></br>
          <h3>The Purpose and Mission</h3>
          <p>
          The mission of Yog Yatra is to guide individuals on a path where 
          they can experience the true meaning of yoga—not just as a 
          physical practice, but as a journey that nurtures the body, mind, 
          and spirit. Through thoughtfully curated retreats, workshops, and 
          immersive yoga sessions, Saloni aims to share her learnings and 
          take participants on a soulful journey that touches their hearts 
          and transforms their lives.
          </p>
        </div>
      </div>
      <div className="about-contentmid">
        <div className="about-textmid"><br></br><br></br>
          <h3>Why Yog Yatra?</h3>
          <p>
          Saloni’s experience has shown that the practice of yoga, when 
          experienced in its fullest essence, has the power to change 
          lives. This realization inspired her to create Yog Yatra as a 
          platform that transcends the conventional approach to yoga. By 
          weaving together yoga philosophy, mindfulness, meditation, and 
          the exploration of ancient wisdom, Yog Yatra seeks to lead 
          participants to a place of profound inner peace and spiritual 
          awakening.
          </p><br></br>
          <h3>Retreats and Journeys</h3>
          <p>
          Under the banner of Yog Yatra, Saloni is elevating the traditional 
          yoga retreat experience to a whole new level by integrating journeys 
          to spiritually significant locations across Bharat. Imagine practicing 
          pranayama and meditation at high-vibrational temples, sitting in 
          serene contemplation at sacred sites, and absorbing the energy of 
          places steeped in centuries of devotion and history. This blend of 
          travel and transformative practice enables participants to connect 
          deeply with themselves and the spiritual heritage of yoga.
          </p><br></br>
        </div>
      </div>
      <div className="about-content1">
        <div className="carousel1">
          <img loading="eager" src={images2[currentImage]} alt={`Slide ${currentImage + 1}`} />
        </div>

        <div className="about-text1"><br></br>
          <h3>Creating a Nurturing Community</h3>
          <p>
          Yog Yatra is not just about individual transformation; it is about 
          building a nurturing community of like-minded souls who embark on this 
          journey together. Saloni’s retreats and workshops provide a space for 
          participants to learn, grow, and support one another as they incorporate 
          the teachings of yoga into their daily lives. Through this community, 
          Saloni envisions a collective journey where each individual’s growth 
          contributes to a shared experience of unity and support.
          </p><br></br>
          <h3>A New Beginning</h3>
          <p>
          As Yog Yatra begins its journey, it invites you to join and discover 
          yoga in its truest form. This is not merely a practice; it is a yatra—a 
          sacred journey towards self-realization and the divine, through the 
          wisdom of ancient teachings and meaningful experiences. Whether you are 
          a seasoned practitioner or a beginner, Yog Yatra welcomes you to become 
          part of a movement that seeks to transform lives, one journey at a time.
          </p><br></br>
        </div>
      </div>
    </div>
  );
};

export default About;
