import React from 'react';

function Refundpol() {
  return (
    <div><br></br>
      <h2>Refund & Cancellation Policy</h2>
      <p>
      We Are Committed to Your Wellness Journey <br></br>We understand that plans can sometimes change. If you are unable to attend the retreat or session you booked, please email us in advance at contact@yogyatra.org. Refunds will be processed based on the policies outlined below. At [Your Yoga Retreat Name], your satisfaction is our priority, and we aim to deliver an exceptional wellness experience. <br></br> <br></br>

POLICY A <br></br>
Policy A applies to all in-person yoga retreats and workshops. <br></br> <br></br>
Cancellations Prior to the Retreat or Workshop <br></br>
If you are unable to attend and inform us before the retreat or workshop commencement date, you will receive a 50% refund (if you inform about the cancellation 25 days before the commencement of the retreat) of your payment. <br></br> <br></br>
No Transfer of Booking <br></br>
Admission or booking cannot be transferred to another participant. <br></br> <br></br>
Transfers to Another Retreat or Batch <br></br>
You may switch to another retreat or workshop before the start of the program without incurring any extra charges. <br></br>If for any reason you are unable to complete the retreat, we would gladly accommodate you in a future retreat or workshop of the same type at no additional cost, subject to availability. <br></br> <br></br>

POLICY B <br></br>
Policy B applies to all self-paced programs (e-learning and virtual yoga courses). <br></br> <br></br>
Cancellations for Self-Paced Programs <br></br>
Refunds for e-learning programs will follow these guidelines: <br></br><br></br>
<table
        style={{
          width: "100%",
          border: "1px solid black",
        }}
      >
        <thead>
          <tr>
            <th>Before of Online Courses</th>
            <th>% Refund You Will Receive</th>
            <th>Is Transfer to Another Program Permitted?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>5 days before the session</td>
            <td>50%</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Or else</td>
            <td>No Refund</td>
            <td>No</td>
          </tr>
        </tbody>
      </table>
      <br></br> <br></br>
Program Extensions <br></br>
If you wish to extend access to a self-paced program, please email us at contact@yogyatra.org. We will extend access for up to one month at no cost. Extensions beyond one month will incur a fee of 50% of the program price. <br></br> <br></br>

IMPORTANT NOTES <br></br>
All requests for cancellations, rescheduling, or substitutions must be submitted via email to contact@yogyatra.org. <br></br>
You will not be eligible for a refund if you have downloaded or accessed course materials. <br></br>
Refunds will be processed within 10–15 business days of your request. <br></br>
Refunds or rescheduling credits cannot be issued for non-attendance. <br></br>
Yogyatra reserves the right to modify these policies at any time without notice. However, the policy in place at the time of your purchase will apply to your booking. <br></br>
If you fail to notify us in writing about cancellations or rescheduling requests, or if you do not attend the retreat, you will not be eligible for a refund or the option to reschedule. <br></br> <br></br>

Cancellations or Rescheduling by Yogyatra <br></br>
We reserve the right to cancel or reschedule a retreat or session at any time due to unforeseen circumstances such as insufficient registrations, instructor unavailability, or other unexpected events. <br></br>
In such cases, we will work with you to either reschedule your booking or issue a full refund. However,  Yogyatra is not liable for any direct, indirect, or consequential damages caused by the cancellation or rescheduling of a retreat or session. <br></br>

      </p>
      </div>
  );
}

export default Refundpol;