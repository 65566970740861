import React, { useState, useEffect } from 'react';
import './Readmore.css';
import video1 from './assets/rishi1.mp4';
import video2 from './assets/rishi2.mp4';
import image1 from './assets/rishi1.jpg';
import image2 from './assets/rishi2.jpg';
import image3 from './assets/rishi3.jpg';
import image4 from './assets/rishi4.jpg';
import rev1 from './assets/rev1.jpeg';
import rev2 from './assets/rev2.JPG';
import try1 from './assets/try1.png';

import i1 from './assets/i1.PNG';
import i2 from './assets/i2.PNG';

//import try2 from './assets/try2.png';
//import rev3 from './assets/rev3.jpeg';

function Readmore1() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Set an interval to change the current image every 4 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 4000); // 4 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const videos = [video1, video2];

  // Testimonial data
  const testimonials = [
    {
      name: "Surabhi Sharma",
      work: "Works with Google",
      title: "Amazing Experience",
      body: "I had a wonderful time at Yogyatra. The atmosphere was very peaceful.",
      image: rev1,
    },
    {
      name: "Parul Singh",
      work: "Medical Educator",
      title: "Life-changing Retreat",
      body: "Yogyatra has transformed my perspective on life and well-being.",
      image: rev2,
    },
    {
      name: "Anupam Gulati",
      work: "Fashion Design",
      title: "Highly Recommend",
      body: "The instructor is amazing, and the location is breathtaking.",
      image: try1,
    },
  ];

  // Carousel images
  const carouselImages = [image1, image2, image3, image4];

  // State to keep track of the current carousel image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Utility function to detect touch devices
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  // Video interaction handler for click (used for touch devices)
  const handleVideoClick = (e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // Handlers for carousel navigation
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="App">

      {/* Main Content */}
      <main>
        {/* Title Section */}
        <section className="title-section">
          <h1>Rishikesh</h1>
        </section>

        <section>
          {/* Carousel */}
          <div className="carousel">
            <button className="carousel-button prev" onClick={goToPreviousSlide}>
              ❮
            </button>
            <img
              src={carouselImages[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="carousel-image"
              preload="auto"
            />
            <button className="carousel-button next" onClick={goToNextSlide}>
              ❯
            </button>
          </div>
        </section>

        {/* Description Section */}
        <section className="description-carousel-section">
          <div className="description">
            <br />
            <br />
            <p>
              At Yogyatra, our retreat in Rishikesh has been a true celebration of yoga, mindfulness, and inner peace. Nestled in the heart of the Himalayas, Rishikesh has provided the perfect sanctuary for our guests to reconnect with nature and themselves. Surrounded by the serene Ganges River and the tranquil mountain air, participants have experienced transformative journeys guided by expert instructors. From sunrise yoga sessions overlooking the river to evening meditations under starlit skies, every moment has been crafted to nurture the body and soul. Each retreat is a testament to Yogyatra's commitment to creating spaces where wellness, community, and inner harmony flourish.
            </p>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-cards">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="stars">★★★★★</div>
                <h3>{testimonial.title}</h3>
                <p>{testimonial.body}</p>
                <div className="reviewer-info">
                  <img
                    src={testimonial.image}
                    alt={`${testimonial.name}'s profile`}
                    preload="auto"
                  />
                  <div>
                    <p>{testimonial.name}</p>
                    <p>{testimonial.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Gallery Section */}
        <section className="gallery">
          {videos.map((video, index) => (
            <div key={index} className="gallery-item">
              <video
                src={video}
                className="gallery-video"
                loop
                playsInline
                preload="auto"
                poster={isTouchDevice() ? (index === 0 ? i1 : i2) : undefined}
                onClick={handleVideoClick}
                // Ensure video plays on hover for non-touch devices (desktop)
                onMouseOver={!isTouchDevice() ? (e) => e.currentTarget.play() : undefined}
                onMouseOut={!isTouchDevice() ? (e) => e.currentTarget.pause() : undefined}
              />
            </div>
          ))}
        </section>
      </main>
    </div>
  );
}

export default Readmore1;
