import React from 'react';

function Terms() {
  return (
    <div>
      <h2>Terms and Conditions</h2>
      <p>
        Welcome to yogyatra.org. By accessing or using the Website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before making a booking. If you do not agree, please refrain from using the Website.
      </p>
      <br />
      <p>
        1. General Information: The Website is owned and operated by Yogyatra (“we,” “our,” or “us”). These terms govern the use of the Website, services, and bookings for yoga retreats.
      </p>
      <br />
      <p>
        2. Eligibility: You must be at least 18 years old or have parental consent to use the Website and make bookings. By providing your information, you confirm it is accurate, complete, and up to date.
      </p>
      <br />
      <p>
        3. Bookings: All bookings are subject to availability and are considered confirmed only after successful payment via our payment gateway and receipt of a confirmation email. Please verify the details of your booking and notify us of any discrepancies within 24 hours.
      </p>
      <br />
      <p>
        4. Payment: Payments are securely processed via Paypal. Prices are displayed in INR and include applicable taxes unless stated otherwise. You are responsible for ensuring sufficient funds for the transaction.
      </p>
      <br />
      <p>
        5. Cancellations and Refunds: Cancellation requests must be made at least 25 days before the retreat start date to be eligible for a refund. Refunds will be processed within 10-15 days of approval, subject to a cancellation fee. We reserve the right to cancel bookings due to unforeseen circumstances, with a full refund issued in such cases.
      </p>
      <br />
      <p>
        6. Use of Firebase for Data Storage: We use Firebase to securely store personal information (e.g., name, email, and payment details). Your data is used solely for booking processing, record maintenance, and service improvement. Refer to our Privacy Policy for more details.
      </p>
      <br />
      <p>
        7. User Responsibilities: You agree to use the Website for lawful purposes only and to refrain from activities that may harm the Website, users, or provided services. Ensure all information provided during booking is accurate.
      </p>
      <br />
      <p>
        8. Liability: We are not responsible for interruptions caused by external factors such as internet outages or payment gateway issues. While we strive for accuracy, we do not guarantee the completeness of Website content.
      </p>
      <br />
      <p>
        9. Privacy and Security: We are committed to protecting your personal data. By using the Website, you consent to the collection, storage, and processing of your data as per our Privacy Policy.
      </p>
      <br />
      <p>
        10. Intellectual Property: All content on the Website, including text, graphics, logos, and images, is owned by Yogyatra. Unauthorized use of Website content is strictly prohibited.
      </p>
      <br />
      <p>
        11. Dispute Resolution: In case of a dispute, you agree to contact us first to resolve the issue amicably.
      </p>
      <br />
      <p>
        12. Changes to Terms: We reserve the right to modify these terms at any time. Changes will take effect upon posting on the Website. Continued use of the Website after changes implies acceptance of the revised terms.
      </p>
      <br />
      <p>
        13. Contact Us: For any queries regarding these Terms and Conditions, contact us at:
        <br />
        Email: contacts@yogyatra.org
        <br />
        Phone: +917798925709
      </p>
      <br />
      <p>
        By using the Website and making a booking, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
      </p>
    </div>
  );
}

export default Terms;
