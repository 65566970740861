import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Video.css';
import rishivideo from './assets/Retreat.mp4';
import backgroundAudio from './assets/Retreat.mp3'; // Import your audio file

function Rishivideo() {
  const [showButton, setShowButton] = useState(false); // State to control button visibility
  const videoRef = useRef(null); // Reference to the video element
  const audioRef = useRef(null); // Reference to the audio element
  const navigate = useNavigate(); // To navigate to another page

  useEffect(() => {
    const handleTimeUpdate = () => {
      const video = videoRef.current;
      if (video) {
        const remainingTime = video.duration - video.currentTime;
        // Show the button when there are 5 seconds left in the video
        if (remainingTime <= 5) {
          setShowButton(true);
        }
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.play().catch((error) => {
        console.error('Autoplay audio failed:', error);
      });
    }
  }, []);

  return (
    <div className="explorer-rishi-container">
      {/* Background audio for the page */}
      <audio ref={audioRef} src={backgroundAudio} loop autoPlay />
      {/* Flexbox for video aligned with the navigation bar */}
      <div className="video-flexbox">
        <div className="video-container">
          <video ref={videoRef} autoPlay className="rishi-video" playsInline>
            <source src={rishivideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      {/* Button that appears during the last 5 seconds */}
      {showButton && (
        <button
          className="read-more-btn"
          onClick={() => navigate('/explorerishi')}
        >
          Read More
        </button>
      )}
    </div>
  );
}

export default Rishivideo;
