import React from 'react';

function Shippingpol() {
  return (
    <div><br></br>
      <h2>Shipping & Delivery Policy</h2>
      <p>
      Last updated on Jan 4th 2024 <br></br>
      Shipping is not applicable for business.
      </p><br></br><br></br><br></br><br></br><br></br>
      <p>Disclaimer: The above content is created at Saloni Rajawat's sole discretion. Paypal shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.</p>
      </div>
  );
}

export default Shippingpol;