import React, { useState } from 'react';
import './Readmore.css';
import { useEffect } from 'react';
import video1 from './assets/vado1.mp4';
import video2 from './assets/vado2.mp4';
import image1 from './assets/vado1.JPEG';
import image2 from './assets/vado2.JPEG';
import image3 from './assets/vado3.JPEG';
import image4 from './assets/vado4.JPEG';
import rev10 from './assets/rev10.jpeg';
import rev11 from './assets/rev11.jpeg';
import rev12 from './assets/rev12.jpeg';

import i1 from './assets/READ4.PNG';
import i2 from './assets/READ44.PNG';



function Readmore4() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Set an interval to change the current image every 4 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 4000); // 4 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const videos = [video1, video2];
  // Testimonial data
  const testimonials = [
    {
      name: "Tarang Chelawat",
      work: "Lawyer",
      title: "Soul-Soothing",
      body: "The calm environment and expert guidance made this retreat magical.",
      image: rev10,
    },
    {
      name: "Chandani Patel",
      work: "CA",
      title: "Exceptional Healing",
      body: "Incredible instructor and location—healing both mentally and physically.",
      image: rev11,
    },
    {
      name: "Sarthak Tyagi",
      work: "IT Professional",
      title: "Renewed Energy",
      body: "Left the retreat feeling refreshed, energised, and completely centered.",
      image: rev12,
    },
  ];

  // Carousel images
  const carouselImages = [
    image1,image2,image3,image4,
  ];

  // State to keep track of the current carousel image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Utility function to detect touch devices
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  // Video interaction handler for click (used for touch devices)
  const handleVideoClick = (e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // Handler for moving to the next image
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  // Handler for moving to the previous image
  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="App">

      {/* Main Content */}
      <main>
        {/* Title Section */}
        <section className="title-section">
          <h1>Vadodara</h1>
        </section>
          
          <section>
          {/* Carousel */}
          <div className="carousel">
            <button className="carousel-button prev" onClick={goToPreviousSlide}>❮</button>
            <img
              src={carouselImages[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-button next" onClick={goToNextSlide}>❯</button>
          </div>
        </section>

        {/* Description Section */}
        <section className="description-carousel-section">
          <div className="description"><br></br><br></br>
            <p>
            At Yogyatra, our retreat in Vadodara, Gujarat, has been a harmonious blend of yoga, cultural immersion, and holistic well-being. Situated in the heart of Gujarat’s vibrant landscape, Vadodara has offered a peaceful retreat where guests can reconnect with themselves and the rich cultural heritage of the region. Participants have embarked on deeply personal and transformative experiences, guided by our yoga instructor. Each moment in our retreat is designed to revitalise the mind, body, and spirit. Every retreat reflects Yogyatra's commitment to fostering a nurturing space where community, self-awareness, and inner balance thrive.
            </p>
          </div>
          </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-cards">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="stars">★★★★★</div>
                <h3>{testimonial.title}</h3>
                <p>{testimonial.body}</p>
                <div className="reviewer-info">
                  <img src={testimonial.image} alt={`${testimonial.name}'s profile`} />
                  <div>
                    <p>{testimonial.name}</p>
                    <p>{testimonial.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <section className="gallery">
  {videos.map((video, index) => (
    <div key={index} className="gallery-item">
      <video
        src={video}
        className="gallery-video"
        loop
        playsInline
        poster={isTouchDevice() ? (index === 0 ? i1 : i2) : undefined}
        onClick={handleVideoClick}
        // Ensure video plays on hover for non-touch devices (desktop)
        onMouseOver={!isTouchDevice() ? (e) => e.currentTarget.play() : undefined}
        onMouseOut={!isTouchDevice() ? (e) => e.currentTarget.pause() : undefined}
      />
    </div>
  ))}
</section>
        </section>
      </main>
    </div>
  );
}

export default Readmore4;