import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './Home.css';
import image11 from './assets/11.jpg'; // Goa
import image3 from './assets/3.jpg'; // Govardhan
import image4 from './assets/vado.JPEG'; // Vado
import image5 from './assets/jaipur1.JPG'; // Jaipur
import image6 from './assets/mum3.jpg'; // Mumbai
import backgroundhome from './assets/homerishi.jpg'; // Rishikesh
import rishikeshcard from './assets/rishikeshcard.png'; // Rishikesh

function Home() {
  const [currentIndex] = useState(0); // Carousel index
  const navigate = useNavigate(); // Initialize the navigate function

  // useEffect to change the hero section every 5 seconds
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Carousel auto-change functionality every 5 seconds
    // const interval = setInterval(() => {
    //   setCurrentIndex((prevIndex) => (prevIndex + 1) % 2); // We have only 2 sections (Rishikesh and Goa)
    // }, 4500); // Change every 4.5 seconds

    // Clean up the interval when the component unmounts
    // return () => clearInterval(interval);
  }, []);

  const heroContent = () => {
    if (currentIndex === 0) {
      return (
        <section
          className="herohome"
          style={{
            backgroundImage: `url(${backgroundhome})` // Rishikesh background image
          }}
        >
          <div className="hero-contenthome">
            <h1>Explore Rishikesh</h1>
            <p>
              Join like-minded souls on an extraordinary exploration of the sacred land of gods, DevBhoomi. Immerse yourself in yoga, meditation, and ancient wisdom amidst serene peaks, powerful temples, and the timeless energy of Uttarakhand. 
              This isn't just travel; it's a journey within. <br></br><br></br>
              Will you answer the call of the mountains?
            </p>
            <button
              className="cta-btnhome"
              onClick={() => navigate('/Explorerishi')} // Navigate to Rishikesh page
            >
              EXPLORE
            </button>
          </div>
        </section>
      );
    // } else if (currentIndex === 1) {
    //   return (
    //     <section
    //       className="herohome"
    //       style={{
    //         backgroundImage: `url(${image5})` // Goa-specific background image
    //       }}
    //     >
    //       <div className="hero-contenthome">
    //         <h1>Explore Goa</h1>
    //         <p>
    //           Unwind by the beach with relaxing yoga and rejuvenating coastal vibes during your retreat in Goa. Embrace the sun, sand, and serenity while deepening your yoga practice in this serene coastal paradise. Escape to the tranquil heart of the Himalayas and join us for an immersive yoga retreat in Goa.
    //         </p>
    //         <button
    //           className="cta-btnhome"
    //           onClick={() => navigate('/goavideo')} // Navigate to Goa page
    //         >
    //           EXPLORE
    //         </button>
    //       </div>
    //     </section>
    //   );
    }
  };

  return (
    <div className="App">
      <main>
        {/* Hero Section */}
        {heroContent()}

        {/* Past Retreats Section */}
        <div className="past-retreatshome">
          <h2>Past Retreats</h2>
          <div className="retreats-gridhome">
            {/* Retreat 1: Rishikesh */}
            <div className="retreat-cardhome">
              <img
                src={rishikeshcard}
                alt="Rishikesh"
                loading="eager"
                preload="auto"
              />
              <div className="card-contenthome">
                <h3>Rishikesh</h3>
                <p>Spiritual haven of Rishikesh with rejuvenating yoga and riverside tranquility.</p>
                <button
                  className="read-more-btnhome"
                  onClick={() => navigate('/readmore1')} // Navigate to Jaipur page
                >
                  Read More
                </button>
              </div>
            </div>

            {/* Retreat 2: Goa */}
            <div className="retreat-cardhome">
              <img
                src={image11}
                alt="Goa"
                loading="eager"
                preload="auto"
              />
              <div className="card-contenthome">
                <h3>Goa</h3>
                <p>Unwind by the beach with relaxing yoga and rejuvenating coastal vibes.</p>
                <button
                  className="read-more-btnhome"
                  onClick={() => navigate('/readmore2')} // Navigate to Goa page
                >
                  Read More
                </button>
              </div>
            </div>

            {/* Retreat 3: Govardhan Ecovillage */}
            <div className="retreat-cardhome">
              <img
                src={image3}
                alt="Govardhan Ecovillage"
                loading="eager"
                preload="auto"
              />
              <div className="card-contenthome">
                <h3>Govardhan Ecovillage</h3>
                <p>Immerse in yoga surrounded by the natural beauty and sustainable living.</p>
                <button
                  className="read-more-btnhome"
                  onClick={() => navigate('/readmore3')} // Navigate to Vadodara page
                >
                  Read More
                </button>
              </div>
            </div>

            {/* Retreat 4: Vadodara */}
            <div className="retreat-cardhome">
              <img
                src={image4}
                alt="Vadodara"
                loading="eager"
                preload="auto"
              />
              <div className="card-contenthome">
                <h3>Vadodara</h3>
                <p>Experience yoga amidst the cultural richness of Gujarat.</p>
                <button
                  className="read-more-btnhome"
                  onClick={() => navigate('/readmore4')} // Navigate to Kerala page
                >
                  Read More
                </button>
              </div>
            </div>

            {/* Retreat 5: Jaipur */}
            <div className="retreat-cardhome">
              <img
                src={image5}
                alt="Jaipur"
                loading="eager"
                preload="auto"
              />
              <div className="card-contenthome">
                <h3>Jaipur</h3>
                <p>Discover the pink city with serene yoga sessions and cultural exploration.</p>
                <button
                  className="read-more-btnhome"
                  onClick={() => navigate('/readmore5')} // Navigate to Udaipur page
                >
                  Read More
                </button>
              </div>
            </div>

            {/* Retreat 6: Mumbai */}
            <div className="retreat-cardhome">
              <img
                src={image6}
                alt="Mumbai"
                loading="eager"
                preload="auto"
              />
              <div className="card-contenthome">
                <h3>Mumbai</h3>
                <p>Balance your energy in the bustling city that never sleeps.</p>
                <button
                  className="read-more-btnhome"
                  onClick={() => navigate('/readmore6')} // Navigate to Mumbai page
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
