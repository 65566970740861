import React, { useState, useEffect } from 'react';
import './Readmore.css';
import video1 from './assets/goa1.mp4';
import video2 from './assets/goa2.mp4';
import image1 from './assets/goa1.jpg';
import image2 from './assets/goa2.jpg';
import image3 from './assets/goa3.jpg';
import image4 from './assets/goa4.jpg';
//import rev4 from './assets/rev4.jpeg';
//import rev5 from './assets/rev5.jpeg';
//import rev6 from './assets/rev6.jpeg';
import try3 from './assets/try3.png';
import try4 from './assets/try4.png';
import try5 from './assets/try5.png';
import i1 from './assets/GOAA1.PNG';
import i2 from './assets/GOAA2.PNG';


function Readmore2() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    // Set an interval to change the current image every 4 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 4000); // 4 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const videos = [video1, video2];

  // Testimonial data
  const testimonials = [
    {
      name: "Seema Hayden",
      work: "Fashion Designer",
      title: "Unforgettable Journey",
      body: "The retreat was a perfect balance of peace and mindfulness.",
      image: try3,
    },
    {
      name: "Shekhar Mahajan",
      work: "Marketing Head",
      title: "Truly Uplifting",
      body: "Every session rejuvenated my body and mind!",
      image: try4,
    },
    {
      name: "Manmeet Barve",
      work: "Dean at Amity",
      title: "Peaceful Escape",
      body: "A serene retreat that helped me find inner peace and relaxation.",
      image: try5,
    },
  ];

  // Carousel images
  const carouselImages = [image1, image2, image3, image4];

  // State to keep track of the current carousel image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Utility function to detect touch devices
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  //Video interaction handler for click (used for touch devices)
  const handleVideoClick = (e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // Handlers for carousel navigation
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="App">
      {/* Main Content */}
      <main>
        {/* Title Section */}
        <section className="title-section">
          <h1>Goa</h1>
        </section>

        {/* Carousel Section */}
        <section>
          <div className="carousel">
            <button className="carousel-button prev" onClick={goToPreviousSlide}>
              ❮
            </button>
            <img
              src={carouselImages[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-button next" onClick={goToNextSlide}>
              ❯
            </button>
          </div>
        </section>

        {/* Description Section */}
        <section className="description-carousel-section">
          <div className="description">
            <br />
            <br />
            <p>
              At Yogyatra, our retreat in Goa has been a vibrant celebration of yoga,
              rejuvenation, and coastal serenity. Nestled along the sun-kissed beaches of
              the Arabian Sea, Goa has offered our guests the ideal setting to unwind,
              reconnect, and rediscover balance. With the soothing sound of ocean waves
              and the warmth of the tropical breeze, participants have embarked on deeply
              transformative journeys, guided by experienced instructors. From energizing
              morning yoga sessions on the beach to tranquil sunset meditations, every
              moment has been thoughtfully curated to refresh both body and soul. Each
              retreat reflects Yogyatra's dedication to fostering an environment where
              wellness, connection, and inner peace thrive.
            </p>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-cards">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="stars">★★★★★</div>
                <h3>{testimonial.title}</h3>
                <p>{testimonial.body}</p>
                <div className="reviewer-info">
                  <img
                    src={testimonial.image}
                    alt={`${testimonial.name}'s profile`}
                  />
                  <div>
                    <p>{testimonial.name}</p>
                    <p>{testimonial.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Gallery Section */}

 <section className="gallery">
  {videos.map((video, index) => (
    <div key={index} className="gallery-item">
      <video
        src={video}
        className="gallery-video"
        loop
        playsInline
        poster={isTouchDevice() ? (index === 0 ? i1 : i2) : undefined}
        onClick={handleVideoClick}
        // Ensure video plays on hover for non-touch devices (desktop)
        onMouseOver={!isTouchDevice() ? (e) => e.currentTarget.play() : undefined}
        onMouseOut={!isTouchDevice() ? (e) => e.currentTarget.pause() : undefined}
      />
    </div>
  ))}
</section>
      </main>
    </div>
  );
}

export default Readmore2;
